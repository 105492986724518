<template>
  <transition name="fade">
      <div class="game page" v-if="ready">
<div class="secondary-header" v-if="ready">
        <div @click="() => { this.$router.push({path: '/games'})}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M15 6L9 12L15 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
             Games
        </div>

        <div class="save" v-ripple @click="save">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="this.game.saved ? 'saved' : ''">
                <path d="M5 21V5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L13.0815 17.1953C12.4227 16.7717 11.5773 16.7717 10.9185 17.1953L5 21Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>

        <div class="back">
            <img :src="gamedata.background_image"/>
        </div>
        <div class="poster">
            <img :src="game.coverArt"/>
        </div>

        <div class="section details">
            <div>
                <h2> {{game.title}}
                    <span v-if="gamedata.released">
                        {{gamedata.released.split('-')[0]}}
                    </span>
                </h2>

                <div class="actions">
                    <button v-ripple @click="start(false)" class="start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none"> <path d="M7 21L17 21" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 16.4V3.6C2 3.26863 2.26863 3 2.6 3H21.4C21.7314 3 22 3.26863 22 3.6V16.4C22 16.7314 21.7314 17 21.4 17H2.6C2.26863 17 2 16.7314 2 16.4Z" stroke="#fff" stroke-width="1.5"/> </svg>
                        Start Game
                    </button>

                    <button v-ripple @click="start(true)" class="stream">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none"> <path d="M2 20.01L2.01 19.9989" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 16C4 16.5 5.5 18 6 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 12C6 12.5 9.5 16 10 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                        Remote Play
                    </button>
                </div>

                <div class="data" v-if="ready">
                  <div class="dataitem">
                        <p> PLATFORM </p>
                        <span>
                            {{game.console}}
                        </span>
                    </div>

                    <div class="dataitem">
                        <p> DEVELOPERS </p>
                        <span v-if="gamedata.developers">
                            {{gamedata.developers.map((x) => { return x.name }).join(', ')}}
                        </span>
                    </div>

                    <div class="dataitem">
                        <p> PUBLISHERS </p>
                        <span v-if="gamedata.publishers">
                            {{gamedata.publishers.map((x) => { return x.name }).join(', ')}}
                        </span>
                    </div>

                    <div class="dataitem">
                        <p> Rating </p>
                        <span>
                            {{gamedata.metacritic}}/100
                        </span>
                    </div>


                    <div class="dataitem" v-if="gamedata.esrb_rating">
                        <p> ESRB </p>
                        <span>
                            {{gamedata.esrb_rating.name}}
                        </span>
                    </div>


                </div>
            </div>
        </div>

        <GameStream></GameStream>

        <div class="section">
            <h3> Related Games</h3>
            <div class="content-row" >
                <GameComp v-for="(g,i) in related" :key="i" :game="g"  v-ripple/>
            </div>
        </div>


        <transition name="fade">
          <div class="bg" v-if="conf" @click="cancelled"/>
        </transition>

        <transition name="fade">
          <div class="pointer" v-if="pairPointer">
            RESYNC CONTROLLER

            <div class="controllers" v-if="!remapCtrls">
                <div v-ripple @click="() => {resyncController(0)}"> <div class="purp-dot dot"/> PLAYER 1 </div>
                <div v-ripple @click="() => {resyncController(1)}"> <div class="white-dot dot"/> PLAYER 2 </div>
            </div>
            <div v-else>
                <div v-ripple @click="() => {resetXone()}">  Reset Xone </div>
            </div>

          </div>
        </transition>

        <transition name="fade">
          <div class="confbox" v-if="conf">
              <div class="lds-ripple" @click="() => {pairPointer = !pairPointer}" :class="{ wheel: remapCtrls && game.console && game.tags.includes('racing')}"><div></div><div></div></div>
              <img style="height: 175px; margin-top: -30px; width: auto" v-if="remapCtrls && game.console && game.tags.includes('racing')" src="/tang.png"/>
              <img v-else src="/tingn.webp"/>

              <span v-if="remapCtrls"> Route controllers through client device via 2.4GHz, or USB </span>
              <span v-else> Connect controllers directly to host via Bluetooth, or USB </span>

              <div class="toggles" @click="() => {remapCtrls = !remapCtrls}">
                  <div class="toggle" :class="{active: !remapCtrls}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none"> <path d="M18 12L6 12"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 16V22M12 22L15 19M12 22L9 19"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 8V2M12 2L15 5M12 2L9 5"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    <span> direct </span>
                  </div>
                  <div class="toggle" :class="{active: remapCtrls}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none"> <path d="M15.4 22H8.6C8.26863 22 8 21.7314 8 21.4V2.6C8 2.26863 8.26863 2 8.6 2H15.4C15.7314 2 16 2.26863 16 2.6V21.4C16 21.7314 15.7314 22 15.4 22Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16 12H23M23 12L21 14M23 12L21 10"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M1 12L3 10M1 12L3 14M1 12H8"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    <span> mirror </span>
                  </div>
              </div>
              <div class="actions">
                  <button v-ripple @click="cancelled"> CANCEL </button>
                  <button v-ripple @click="confed"> START GAME </button>
              </div>
          </div>
        </transition>

      </div>
  </transition>
</template>

<script>

import GameComp from '@/components/Game.vue'
import GameStream from '../components/GameStream.vue'
export default {
  name: 'Game',

  components: {
    GameComp,
    GameStream
},

  watch: {
    $route() {
        this.ready = false
        this.loadDetails()
      }

  },

  data: function(){
    return {
        pairPointer: false,
        ready: false,
        conf: false,
        confing: null,
        remapCtrls: false,
        game: null,
        set: true,
        gamedata: null,
        related: [],
    }
  },

  methods: {
    start(headless){
        if(headless){
            if(!window.did || window.did === 'WR-ULTRA'){
                if(this.set){
                    this.$root.$emit('collectDid', {cb: this.start.bind(this), params: headless })
                    this.set = false
                    return
                }
            }
        }else{
            window.did = 'WR-ULTRA'
            this.$root.$emit('newDid', window.did)
        }

        this.conf = true;
        this.confing = {
            ...this.game,
            color: window.getAssetColor(this.game.asset),
            type: 'game',
            did: window.did,
            logos: [this.game.coverArt],
            streaming: headless,
            remap: this.remapCtrls,
            name: this.game.title,
            related: [],
            id: this.game.id,
        }
        delete this.confing.asset
    },


    async save(){
        let data = {
            id: this.game.id,
            type: 'game',
            ref: window.location.path,
            name: this.game.title,
            imgs: [this.game.coverArt],
        }

        const req = await fetch(process.env.VUE_APP_SERVER + `/save`, {method: 'POST', body: JSON.stringify(data), headers: { "Content-Type": "application/json" } })
        const res = await req.json()
        this.game.saved = res.saved
    },

    cancelled(){
        this.conf=false
        this.pairPointer = false
        this.confing = null
    },

    confed(){
        this.confing.remap = this.remapCtrls
        if(this.confing.streaming){
            if(!window.did){
                this.$root.$emit('collectDid', {cb: this.confed.bind(this), params: undefined })
                return
            }

            fetch(process.env.VUE_APP_SERVER + '/startGameStream', {method: 'POST', body: JSON.stringify(this.confing), headers: { "Content-Type": "application/json" } })
            this.cancelled()
            return

        }else{
            fetch(process.env.VUE_APP_SERVER + '/start', {method: 'POST', body: JSON.stringify({...this.confing}), headers: { "Content-Type": "application/json" } })
            this.cancelled()
        }

        this.set = true
    },

    resetXone(){
        fetch(process.env.VUE_APP_SERVER + '/resetXone?did='+ window.did)
        this.pairPointer = false
    },

    resyncController(ctrl){
        fetch(process.env.VUE_APP_SERVER + '/resyncController?did='+ window.did + '&controller='+ ctrl)
        this.pairPointer = false
    },

    async loadDetails(){
        let request = await fetch(process.env.VUE_APP_SERVER + '/game?id='+ this.$route.params.id)
        let data = await request.json()
        this.game = data.game
        this.related = data.related

        let detailrequest = await fetch(`https://api.rawg.io/api/games/${this.game.id}?key=7dd31c9796624e5a923da9980683f901`)
        let detaildata = await detailrequest.json()
        this.gamedata = detaildata
          console.log(this.gamedata)
        this.ready = true
    }
  },

  computed: {

  },

  mounted: async function(){
      this.loadDetails()
  }
}

</script>

<style scoped lang="scss">

.confbox{
    position: fixed;
    width: 300px;
    height: 400px;
    background-color: white;
    top: 15vh;
    left: calc(50vw - 150px);
    z-index: 999;
    overflow: hidden;
    border-radius: 16px;
    .toggles{
        display: flex;
        align-items: center;
        justify-content:center;
        width: 40%;
        margin: 0 auto;
        border: 1px solid #0984e3;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 40px;
        .toggle{
            border-radius: 12px;
            color: black;
            stroke: black;
            display: flex;
            align-items: center;
            margin: 2px;
            flex-grow: 1;
            flex-direction: row;
            justify-content: center;
            span{
                margin: 0;
                padding: 0;
                padding-right: 5px;
                width: 0;
                overflow: hidden;
                opacity:0;
                display: none;
            }

            &.active{
                stroke: white;
                span{
                    color: white;
                    opacity: 1;
                    padding-left: 5px;
                    width: auto;
                    display: block;
                }
                background-color: #0984e3;
            }
        }
    }

    img{
        width: 75%;
        display: block;
        padding: 50px 0;
        padding-bottom: 25px;
        margin: 0 auto;
    }

    span{
        color: black;
        width: 85%;
        display: block;
        margin: 0 auto;
        padding: 20px 0;
        padding-top: 0;
        text-align: center;
    }
    .actions{
        display: flex;
        flex-direction: row;
        button{
            width: 50%;
            font-weight: bold;
            border: 1px solid white;
            background-color: none;
            padding: 20px;
        }
    }
}
.bg{
    background-color: rgba(black, 0.8);
    backdrop-filter: blur(10px);
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 53px;
  left: 132px;
  &.wheel{
        top: 91.5px;

  }
}
.lds-ripple div {
  position: absolute;
  border: 2px solid black;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 32px;
    height: 32px;
    opacity: 0;
  }
}
h3{
  margin-left: 20px;
}
.back{
    width: 100vw;
    height: 50vh;
    object-fit:cover;
    object-position: center;
    position: relative;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(20px);
        width: 100%;
        height: 100%;
    }
    img{
        min-width: 100%;
        min-height: 40vh;
        max-height: 45vh
    }
}
.poster{
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    right: 0;
    position: relative;
    margin-bottom: calc(-40vh + 40px);
    pointer-events: none;
    img{
        width: 100%;
        transform: translateY(-40vh);
        min-height:  400px;
        height: 100%;
        max-width: 300px;
        border-radius: 12px;
        box-shadow: -3px 3px 12px 2px rgba(0, 0, 0, 0.5);
    }
}

.details{
    h2{
        width: 80%;
        margin: 0 auto;
        text-align: center;
        span{
            padding-top: 10px;
            display: block;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
        padding-bottom: 24px;
    }
    p{

        width: 90%;
        margin: 0 auto;
        text-align: justify;
    }
    .actions{
        display: flex;
        width: 90%;
        margin: 0 auto;
        justify-content: space-between;
        button{
            padding: 10px 0;
            width: 49%;
            border: 1px solid white;
            border-radius: 5px;
            background-color: transparent;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;

            svg{ padding-right: 5px; }
        }
        .stream{
            border-color: #0984e3;
            background-color: rgba(#0984e3, 0.45)
        }
        .start{
            border-color: #00b894;
            background-color: rgba(#00b894, 0.45)
        }
    }
}
.data{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
    padding-top: 30px;
    flex-wrap: wrap;
    .dataitem{
        width: 45%;
        margin-top: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid #444;
        p{
            font-weight: bold;
            font-size: 9px;
            padding: 0;
            margin: 0;
            padding-bottom: 5px;
        }
        span{
            font-size: 11px;
            padding: 0;
            margin: 0;
            color: #ccc;
        }
    }
}
.pointer{
    background-color: black;
    border-radius: 10px;
    position: fixed;
    top: 9vh;
    width: 200px;
    left: calc(50vw - 100px);
    z-index: 999999;
    padding: 10px;
    text-align: center;
    font-size: 9px;
    &:after{
       position: absolute;
       content: '';
       bottom: -10px;
       left: calc(50% - 25px);
       width: 0px;
       height: 0px;
       border-style: solid;
       border-width: 15px 15px 0 15px;
       border-color: black transparent transparent transparent;
       transform: rotate(0deg);
    }
    .controllers{
        display: flex;
        padding-top: 10px;
        > div{
            width: 50%;
            padding: 10px;
            font-size: 12px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            .dot{
                margin: 5px;
                width: 10px;
                height: 10px;
                background-color: white;
                border-radius: 50%;
                &.purp-dot{
                    background-color: #6c5ce7;
                }
            }

            &:first-of-type{
                border-right: 1px solid white;
            }

        }
    }
}
.section.details{
    padding-top: 2.5vh;

}
</style>
